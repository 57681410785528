// Your variable overrides
@import "./boot";


// custom parts of bootstrap
// ==============================================

// no custom: all of it
// @import "~bootstrap/scss/bootstrap";

/*
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
*/


// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";


@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/tables";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "~bootstrap/scss/utilities/api";

// 6. Add additional custom code here

/*
// Bootstrap 4 Optional parts
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid"; // needed for container
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/card";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/print";
@import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/utilities";
*/

// increase app booting progress indicator
// ==============================================
@include progress(2);

// Override some of bootstrap features
// ==============================================

// add extra small buttons
*.btn-group-xs > .btn, *.btn-xs {
    padding: .125rem .375rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

// add dropdown menus with not caret
.dropdown-toggle-no-caret::after {
    display: none;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: $secondary;
}

// modify list items and table background-color
.list-group .list-group-item, .table-primary td {
    background-color: $bg-alt;

    a {
        color: $body-color; //darken($link-color, 10%);
    }
}
.list-group-item-action:hover {
  background-color: $secondary !important;
}
.table-primary.hoverable tr:hover td {
    cursor: pointer;
    // background: $list-group-hover-bg;
    background-color: $secondary;
    color: white;
}

.table .thead-grey th {
    background-color: $gray-600;
    color: $light;
}

.dropdown-toggle.no-caret::after {
    display: none;
}

// modifies labels
label {
    font-weight: bold;
}

.table th {
  font-family: $headings-font-family;
}

.no-carret, .dropright {
    &::after {
        display: none !important;
    }
}

.form-control, .form-control:focus, .form-select {
    background-color: white; // as it was in Bootstrap 4
}